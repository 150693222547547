<template>
    <div class="help">
        <div class="help__section">
            <div class="help__title">
                <router-link :to="{ name: 'help' }" class="help__title-back">
                    <svg-vue icon="back-arrow" />
                </router-link>
                {{ $t("help.ticket.title", { number: ticket.id }) }}
            </div>

            <div class="ticket">
                <div class="ticket__main">
                    <div class="help__box">
                        <div class="help__box-wrapper chat">
                            <div class="chat__head">{{ $t("help.ticket.chatHead") }}</div>
                            <ChatMessages class="chat__body" />

                            <div class="chat__bottom" v-if="activeTicket">
                                <ChatSubmit />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ticket__sidebar">
                    <div class="help__box">
                        <div class="help__box-wrapper">
                            <div class="ticket__info">
                                <span>{{ $t("help.ticket.sidebar.status") }}</span>
                                <strong>{{ ticket.status }}</strong>
                            </div>
                            <div class="ticket__info">
                                <span>{{ $t("help.ticket.sidebar.date") }}</span>
                                <strong>{{ dateCreated }}</strong>
                            </div>
                            <div class="ticket__info">
                                <span>{{ $t("help.ticket.sidebar.topic") }}</span>
                                <strong>{{ ticket.topic }}</strong>
                            </div>
                            <div class="ticket__info">
                                <span>{{ $t("help.ticket.sidebar.number") }}</span>
                                <strong>{{ ticket.id }}</strong>
                            </div>
                            <div class="ticket__info">
                                <span>{{ $t("help.ticket.sidebar.moderator") }}</span>
                                <strong v-if="ticket.admin">{{ ticket.admin.name }}</strong>
                            </div>
                            <div class="ticket__sidebar-cta" v-if="activeTicket">
                                <UiButton
                                    type="secondary"
                                    size="sm"
                                    :text="$t('help.ticket.cancel.cta')"
                                    @click="() => setCancelModal({ visible: true })"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UiModal :visible="modalVisible" :title="$t('help.cancelModal.title')" @closeModal="closeModal">
            <div class="cancelModal">
                <div class="cancelModal__description">{{ $t("help.cancelModal.description") }}</div>
                <div class="cancelModal__actions">
                    <UiButton :text="$t('help.cancelModal.actions.confirm')" @click="handleCancelTicket" />
                    <UiButton type="secondary" :text="$t('help.cancelModal.actions.cancel')" @click="closeModal" />
                </div>
            </div>
        </UiModal>
        <UiModal :visible="modalSuccessVisible" @closeModal="closeSuccessModal">
            <div class="cancelModal__description">{{ $t("help.cancelModal.done") }}</div>
            <div class="cancelModal__actions">
                <UiButton :text="$t('help.cancelModal.actions.done')" @click="closeSuccessModal" />
            </div>
        </UiModal>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"
import ChatMessages from "@/components/Help/Chat/Messages.vue"
import ChatSubmit from "@/components/Help/Chat/Submit.vue"

export default {
    components: { ChatMessages, ChatSubmit },

    computed: {
        modalVisible() {
            return this.cancelModal.visible
        },
        modalSuccessVisible() {
            return this.cancelSuccessModal.visible
        },
        dateCreated() {
            return this.$moment(this.ticket.created_at).format("DD.MM.YYYY")
        },
        activeTicket() {
            return this.ticket.status !== "Closed"
        },
        ...mapState("ticket", ["ticket"]),
        ...mapGetters("ui", ["cancelModal", "cancelSuccessModal"]),
    },
    methods: {
        async handleCancelTicket() {
            const closed = await this.closeTicket({ id: this.$route.params.id })

            if (closed) {
                this.closeModal()
                this.setCancelSuccessModal({ visible: true })
            }
        },
        closeModal() {
            this.setCancelModal({ visible: false })
        },
        closeSuccessModal() {
            this.setCancelSuccessModal({ visible: false })
        },
        ...mapMutations("ui", ["setCancelModal", "setCancelSuccessModal"]),
        ...mapActions("ticket", ["closeTicket"]),
    },
}
</script>

<style lang="scss" scoped>
.ticket {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    &__main,
    &__sidebar {
        width: 100%;
        min-width: 1px;
        min-height: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
    &__main {
        flex: 0 0 60%;
        max-width: 60%;
    }
    &__sidebar {
        flex: 0 0 40%;
        max-width: 40%;
    }
    &__sidebar-cta {
        margin-top: 20px;
        button {
            border-radius: 10px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: calc(24 / 16);
        span {
            display: inline-block;
            color: #c5c5c5;
            margin-right: 10px;
        }
        strong {
            font-weight: 600;
        }
    }
}

.chat {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    height: 100vh;
    max-height: calc(100vh - 100px - 75px - 100px);
    &__head {
        flex: 0 0 auto;
        position: relative;
        z-index: 2;
        font-weight: 600;
        font-size: 18px;
        line-height: calc(27 / 18);
        background: var(--color-white-3);
        text-align: center;
        padding: 20px 10px;
        border-bottom: 1px solid var(--color-orange-800);
    }
    &__body {
        flex: 1 1 auto;
        position: relative;
        z-index: 2;
        overflow-y: auto;
        padding: 0 10px;
    }
    &__bottom {
        flex: 0 0 auto;
        position: relative;
        z-index: 2;
        background: var(--color-white-3);
        border-top: 1px solid var(--color-orange-800);
    }
}

@media (max-width: (991px)) {
    .chat {
        max-height: calc(100vh - 100px - 75px - 150px);
    }
}

.cancelModal {
    margin-top: -22px;
    &__description {
        font-weight: 400;
        font-size: 18px;
        line-height: calc(27 / 18);
    }
    &__actions {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        button {
            flex: 1 1 auto;
            max-width: 211px;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .ticket {
        &__main {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &__sidebar {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
</style>
